<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search reviews"
          type="text"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="reviews"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        #column-filter="{ column, updateFilters }"
      >
        <span v-if="column.label === 'Operation'">
          <treeselect
            :append-to-body="true"
            :normalizer="filterNormalizer"
            :options="operations"
            class="form-control p-0 border-0"
            placeholder="Select Phase, Subphase or Activities"
            style="border-collapse: separate"
            @input="(val) => updateFilters(column, val)"
          />
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field === 'action'">
          <b-button
            :to="{ name: 'mentor-review-score', params: { pid: $route.params.id, apid: props.row.id, aid: $route.params.sid, rid: $route.params.pid }}"
            class="mr-50"
            size="sm"
            variant="outline-primary"
          >
            <span>Open</span>
          </b-button>
          <b-button
            v-if="props.row.review_status==='disabled'"
            :to="{ name: 'mentor-review-evaluations', params: { pid: $route.params.id, apid: props.row.id, aid: $route.params.aid }}"
            size="sm"
            variant="outline-success"
          >
            <span>Report</span>
          </b-button>

        </span>

        <span v-else-if="props.column.field === 'title'">
          {{ props.formattedRow[props.column.field] }}<br><b-badge
            v-b-tooltip="'Review Type'"
            variant="primary"
            v-text="props.row.type"
          />
        </span>

        <span v-else-if="props.column.label === 'Operation'">
          {{ props.row.programs_operationstable.title }} <br><b-badge
            v-b-tooltip="'Operation Type'"
            variant="primary"
            v-text="props.row.programs_operationstable.operations_type"
          />
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="selectStartup"
      ok-only
      ok-title="OK"
      size="lg"
      title="Report"
    >
      <p class="my-1">
        No report available
      </p>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BBadge, BButton, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'
import Treeselect from '@riophae/vue-treeselect'

export default {
  components: {
    BBadge,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    Treeselect,
  },
  data() {
    return {
      reviews: [],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Operation',
          field(row) {
            return row.programs_operationstable.title
          },
          width: '45%',
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Action',
          field: 'action',
          width: '13rem',
          sortable: false,
        },
      ],
      operations: [],
      operationsList: [],
    }
  },
  methods: {
    filterNormalizer(node) {
      if (!node) return {}
      return {
        id: node.title || node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
  },
  apollo: {
    operations: {
      query() {
        return gql`{
          programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "Phase"}}) {
                id
                title
                programs_operationstables(where: {operations_type: {_eq: "SubPhase"}}) {
                  id
                  title
                  programs_operationstables {
                    id
                    title
                  }
                }
              }
            }`
      },
      update(data) {
        const arr = []
        data.programs_operationstable.forEach(phase => {
          arr.push(phase.id)
          phase.programs_operationstables.forEach(subPhase => {
            arr.push(subPhase.id)
            subPhase.programs_operationstables.forEach(a => arr.push(a.id))
          })
        })
        this.operationsList = arr // For Reviews query
        return data.programs_operationstable
      },
    },
    reviews: {
      query() {
        return gql`
      {
        programs_assignmenttable(order_by: {id: desc},where: {programs_operationstable: {programs_basicinfo: {id: {_eq:${this.$route.params.id}}}}, review_status: {_neq: "draft"}}) {
        id
        operations_id
        title
        type
        review_status
        programs_operationstable {
          title
          status
          operations_type
        }
      }
      }`
      },
      update: data => data.programs_assignmenttable,

    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
