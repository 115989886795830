<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-between p-0 pb-1 mb-1 border-bottom"
          >
            <div class="d-flex align-items-center p-0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2">
                Milestones
              </h3>
            </div>
          </b-card-header>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search Milestone"
                type="text"
              />
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <span v-if="props.column.field === 'action'">
                <b-button
                  :to="{ name: 'mentor-milestone-review', params: { id: $route.params.id, pid: $route.params.pid, sid: $route.params.sid, mid: props.row.id } }"
                  class="mr-50"
                  size="sm"
                  variant="outline-primary"
                >
                  <span>Open</span>
                </b-button>
              </span>

              <span v-else-if="props.column.field === 'stage_number'">
                <b-badge
                  v-b-tooltip="'Stage ' + props.row.stage_number"
                  variant="primary"
                  v-text="props.row.stage_number"
                />
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import gql from 'graphql-tag'
import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCardHeader,
    BRow,
    BCol,
    BCard,
    BBadge,
    BOverlay,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      rows: [], // Data for the table rows
      searchTerm: '', // Search term for filtering rows
      selectedRow: 0, // Index of the selected row
      pageLength: 25, // Number of rows per page
      filter: {
        status: 'Current', // Filter status
      },
      columns: [
        {
          label: 'Stage', // Column label
          field: 'stage_number', // Field name in the data
        },
        {
          label: 'Milestone', // Column label
          field: 'title', // Field name in the data
        },
        {
          label: 'Recommendation', // Column label
          field(row) {
            return row.programs_assignmenttables[0]?.programs_assignmentscorerecords[0]?.recommendation
          },
        },
        {
          label: 'Verdict', // Column label
          field(row) {
            return row.programs_assignmenttables[0]?.programs_assignmentscorerecords[0]?.verdict
          },
        },
        {
          label: 'Action', // Column label
          field: 'action', // Field name in the data
          width: '13rem', // Column width
          sortable: false, // Disable sorting for this column
        },
      ],
    }
  },
  apollo: {
    rows: {
      query() {
        return gql`
       query GetMilestones($startupParticipantId: Int!) {
         programs_progressstagetable(where: {startup_participant_id: {_eq: $startupParticipantId }}, order_by: {stage_number: asc}) {
            title
            stage_number
            id
            programs_assignmenttables {
              programs_assignmentscorerecords {
                 recommendation
                 verdict
              }
            }
          }
      }`
      },
      variables() {
        return {
          startupParticipantId: this.$route.params.sid,
        }
      },
      update: data => data.programs_progressstagetable,
    },
  },
}
</script>
